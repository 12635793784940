<script setup lang="ts"></script>

<template>
    <svg
        width="228"
        height="240"
        viewBox="0 0 228 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_273_779"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="228"
            height="240"
        >
            <rect
                width="228"
                height="240"
                rx="15"
                fill="url(#paint0_radial_273_779)"
            />
        </mask>
        <g mask="url(#mask0_273_779)">
            <g style="mix-blend-mode: screen" opacity="0.02">
                <path
                    d="M-151.853 -368.412L-147.949 -366.158L-471.755 194.689L-475.659 192.435L-151.853 -368.412Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-144.046 -363.904L-140.142 -361.65L-463.947 199.197L-467.851 196.943L-144.046 -363.904Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-136.238 -359.396L-132.334 -357.142L-456.139 203.705L-460.043 201.451L-136.238 -359.396Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-128.43 -354.888L-124.527 -352.635L-448.332 208.213L-452.236 205.959L-128.43 -354.888Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-120.623 -350.381L-116.719 -348.127L-440.524 212.72L-444.428 210.466L-120.623 -350.381Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-112.815 -345.873L-108.911 -343.619L-432.717 217.228L-436.62 214.974L-112.815 -345.873Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-105.008 -341.365L-101.104 -339.111L-424.909 221.736L-428.813 219.482L-105.008 -341.365Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-97.2 -336.858L-93.2961 -334.604L-417.101 226.243L-421.005 223.99L-97.2 -336.858Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-89.3923 -332.35L-85.4885 -330.096L-409.294 230.751L-413.198 228.497L-89.3923 -332.35Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-81.5847 -327.842L-77.6809 -325.588L-401.486 235.259L-405.39 233.005L-81.5847 -327.842Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-73.7771 -323.334L-69.8733 -321.08L-393.679 239.767L-397.582 237.513L-73.7771 -323.334Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-65.9695 -318.827L-62.0657 -316.573L-385.871 244.274L-389.775 242.021L-65.9695 -318.827Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-58.1619 -314.319L-54.2581 -312.065L-378.063 248.782L-381.967 246.528L-58.1619 -314.319Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-50.3543 -309.811L-46.4505 -307.557L-370.256 253.29L-374.159 251.036L-50.3543 -309.811Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-42.5466 -305.303L-38.6428 -303.05L-362.448 257.798L-366.352 255.544L-42.5466 -305.303Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-34.739 -300.796L-30.8352 -298.542L-354.64 262.305L-358.544 260.051L-34.739 -300.796Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-26.9314 -296.288L-23.0276 -294.034L-346.833 266.813L-350.737 264.559L-26.9314 -296.288Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-19.1238 -291.78L-15.22 -289.526L-339.025 271.321L-342.929 269.067L-19.1238 -291.78Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-11.3162 -287.272L-7.41236 -285.019L-331.218 275.828L-335.121 273.575L-11.3162 -287.272Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M-3.50856 -282.765L0.39525 -280.511L-323.41 280.336L-327.314 278.082L-3.50856 -282.765Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M4.29905 -278.257L8.20286 -276.003L-315.602 284.844L-319.506 282.59L4.29905 -278.257Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M12.1067 -273.749L16.0105 -271.495L-307.795 289.352L-311.699 287.098L12.1067 -273.749Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M19.9143 -269.242L23.8181 -266.988L-299.987 293.859L-303.891 291.606L19.9143 -269.242Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M27.7219 -264.734L31.6257 -262.48L-292.18 298.367L-296.083 296.113L27.7219 -264.734Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M35.5295 -260.226L39.4333 -257.972L-284.372 302.875L-288.276 300.621L35.5295 -260.226Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M43.3371 -255.718L47.2409 -253.465L-276.564 307.383L-280.468 305.129L43.3371 -255.718Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M51.1448 -251.211L55.0486 -248.957L-268.757 311.89L-272.66 309.636L51.1448 -251.211Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M58.9524 -246.703L62.8562 -244.449L-260.949 316.398L-264.853 314.144L58.9524 -246.703Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M66.76 -242.195L70.6638 -239.941L-253.141 320.906L-257.045 318.652L66.76 -242.195Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M74.5676 -237.687L78.4714 -235.434L-245.334 325.414L-249.238 323.16L74.5676 -237.687Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M82.3752 -233.18L86.279 -230.926L-237.526 329.921L-241.43 327.667L82.3752 -233.18Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M90.1828 -228.672L94.0866 -226.418L-229.719 334.429L-233.622 332.175L90.1828 -228.672Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M97.9905 -224.164L101.894 -221.91L-221.911 338.937L-225.815 336.683L97.9905 -224.164Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M105.798 -219.657L109.702 -217.403L-214.103 343.444L-218.007 341.191L105.798 -219.657Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M113.606 -215.149L117.509 -212.895L-206.296 347.952L-210.2 345.698L113.606 -215.149Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M121.413 -210.641L125.317 -208.387L-198.488 352.46L-202.392 350.206L121.413 -210.641Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M129.221 -206.133L133.125 -203.88L-190.681 356.968L-194.584 354.714L129.221 -206.133Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M137.029 -201.626L140.932 -199.372L-182.873 361.475L-186.777 359.221L137.029 -201.626Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M144.836 -197.118L148.74 -194.864L-175.065 365.983L-178.969 363.729L144.836 -197.118Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M152.644 -192.61L156.548 -190.356L-167.258 370.491L-171.161 368.237L152.644 -192.61Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M160.451 -188.102L164.355 -185.849L-159.45 374.999L-163.354 372.745L160.451 -188.102Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M168.259 -183.595L172.163 -181.341L-151.642 379.506L-155.546 377.252L168.259 -183.595Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M176.067 -179.087L179.97 -176.833L-143.835 384.014L-147.739 381.76L176.067 -179.087Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M183.874 -174.579L187.778 -172.325L-136.027 388.522L-139.931 386.268L183.874 -174.579Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M191.682 -170.072L195.586 -167.818L-128.22 393.029L-132.123 390.776L191.682 -170.072Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M199.489 -165.564L203.393 -163.31L-120.412 397.537L-124.316 395.283L199.489 -165.564Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M207.297 -161.056L211.201 -158.802L-112.604 402.045L-116.508 399.791L207.297 -161.056Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M215.105 -156.548L219.008 -154.294L-104.797 406.553L-108.701 404.299L215.105 -156.548Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M222.912 -152.041L226.816 -149.787L-96.9891 411.06L-100.893 408.807L222.912 -152.041Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M230.72 -147.533L234.624 -145.279L-89.1815 415.568L-93.0853 413.314L230.72 -147.533Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M238.528 -143.025L242.431 -140.771L-81.3739 420.076L-85.2777 417.822L238.528 -143.025Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M246.335 -138.517L250.239 -136.264L-73.5663 424.584L-77.4701 422.33L246.335 -138.517Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M254.143 -134.01L258.047 -131.756L-65.7587 429.091L-69.6625 426.837L254.143 -134.01Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M261.95 -129.502L265.854 -127.248L-57.951 433.599L-61.8549 431.345L261.95 -129.502Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M269.758 -124.994L273.662 -122.74L-50.1434 438.107L-54.0472 435.853L269.758 -124.994Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M277.566 -120.486L281.469 -118.233L-42.3358 442.614L-46.2396 440.361L277.566 -120.486Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M285.373 -115.979L289.277 -113.725L-34.5282 447.122L-38.432 444.868L285.373 -115.979Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M293.181 -111.471L297.085 -109.217L-26.7206 451.63L-30.6244 449.376L293.181 -111.471Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M300.988 -106.963L304.892 -104.709L-18.9129 456.138L-22.8168 453.884L300.988 -106.963Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M308.796 -102.456L312.7 -100.202L-11.1053 460.645L-15.0092 458.392L308.796 -102.456Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M316.604 -97.9478L320.507 -95.694L-3.29774 465.153L-7.20152 462.899L316.604 -97.9478Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M324.411 -93.4401L328.315 -91.1862L4.50991 469.661L0.606086 467.407L324.411 -93.4401Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M332.219 -88.9324L336.123 -86.6785L12.3175 474.169L8.41369 471.915L332.219 -88.9324Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M340.027 -84.4247L343.93 -82.1708L20.1251 478.676L16.2213 476.422L340.027 -84.4247Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M347.834 -79.9169L351.738 -77.6631L27.9327 483.184L24.0289 480.93L347.834 -79.9169Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M355.642 -75.4092L359.546 -73.1553L35.7404 487.692L31.8365 485.438L355.642 -75.4092Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M363.449 -70.9015L367.353 -68.6476L43.548 492.199L39.6441 489.946L363.449 -70.9015Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M371.257 -66.3937L375.161 -64.1399L51.3556 496.707L47.4518 494.453L371.257 -66.3937Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M379.065 -61.886L382.968 -59.6322L59.1632 501.215L55.2594 498.961L379.065 -61.886Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M386.872 -57.3783L390.776 -55.1244L66.9708 505.723L63.067 503.469L386.872 -57.3783Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M394.68 -52.8706L398.584 -50.6167L74.7784 510.23L70.8746 507.977L394.68 -52.8706Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M402.487 -48.3628L406.391 -46.109L82.586 514.738L78.6823 512.484L402.487 -48.3628Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M410.295 -43.8551L414.199 -41.6012L90.3937 519.246L86.4899 516.992L410.295 -43.8551Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M418.103 -39.3474L422.007 -37.0935L98.2013 523.754L94.2975 521.5L418.103 -39.3474Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M425.91 -34.8396L429.814 -32.5858L106.009 528.261L102.105 526.007L425.91 -34.8396Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M433.718 -30.3319L437.622 -28.0781L113.816 532.769L109.913 530.515L433.718 -30.3319Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M441.526 -25.8242L445.429 -23.5703L121.624 537.277L117.72 535.023L441.526 -25.8242Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M449.333 -21.3165L453.237 -19.0626L129.432 541.785L125.528 539.531L449.333 -21.3165Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M457.141 -16.8087L461.045 -14.5549L137.239 546.292L133.336 544.038L457.141 -16.8087Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M464.948 -12.301L468.852 -10.0471L145.047 550.8L141.143 548.546L464.948 -12.301Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M472.756 -7.79327L476.66 -5.5394L152.855 555.308L148.951 553.054L472.756 -7.79327Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M480.564 -3.28555L484.467 -1.03168L160.662 559.815L156.758 557.562L480.564 -3.28555Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M488.371 1.2222L492.275 3.47604L168.47 564.323L164.566 562.069L488.371 1.2222Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M496.179 5.72992L500.083 7.9838L176.277 568.831L172.374 566.577L496.179 5.72992Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M503.986 10.2376L507.89 12.4915L184.085 573.339L180.181 571.085L503.986 10.2376Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M511.794 14.7454L515.698 16.9992L191.893 577.846L187.989 575.593L511.794 14.7454Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M519.602 19.2531L523.506 21.507L199.7 582.354L195.796 580.1L519.602 19.2531Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M527.409 23.7608L531.313 26.0147L207.508 586.862L203.604 584.608L527.409 23.7608Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M535.217 28.2686L539.121 30.5224L215.316 591.37L211.412 589.116L535.217 28.2686Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M543.025 32.7763L546.928 35.0302L223.123 595.877L219.219 593.623L543.025 32.7763Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M550.832 37.284L554.736 39.5379L230.931 600.385L227.027 598.131L550.832 37.284Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M558.64 41.7917L562.544 44.0456L238.738 604.893L234.835 602.639L558.64 41.7917Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M566.447 46.2995L570.351 48.5533L246.546 609.4L242.642 607.147L566.447 46.2995Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M574.255 50.8072L578.159 53.0611L254.354 613.908L250.45 611.654L574.255 50.8072Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M582.063 55.3149L585.966 57.5688L262.161 618.416L258.257 616.162L582.063 55.3149Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M589.87 59.8227L593.774 62.0765L269.969 622.924L266.065 620.67L589.87 59.8227Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M597.678 64.3304L601.582 66.5843L277.776 627.431L273.873 625.178L597.678 64.3304Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M605.486 68.8381L609.389 71.092L285.584 631.939L281.68 629.685L605.486 68.8381Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M613.293 73.3459L617.197 75.5997L293.392 636.447L289.488 634.193L613.293 73.3459Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M621.101 77.8536L625.005 80.1075L301.199 640.955L297.295 638.701L621.101 77.8536Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M628.908 82.3613L632.812 84.6152L309.007 645.462L305.103 643.208L628.908 82.3613Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M636.716 86.869L640.62 89.1229L316.815 649.97L312.911 647.716L636.716 86.869Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M644.524 91.3768L648.427 93.6306L324.622 654.478L320.718 652.224L644.524 91.3768Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M652.331 95.8845L656.235 98.1384L332.43 658.985L328.526 656.732L652.331 95.8845Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M660.139 100.392L664.043 102.646L340.237 663.493L336.334 661.239L660.139 100.392Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M667.946 104.9L671.85 107.154L348.045 668.001L344.141 665.747L667.946 104.9Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M675.754 109.408L679.658 111.662L355.853 672.509L351.949 670.255L675.754 109.408Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M683.562 113.915L687.465 116.169L363.66 677.016L359.756 674.763L683.562 113.915Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M691.369 118.423L695.273 120.677L371.468 681.524L367.564 679.27L691.369 118.423Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M699.177 122.931L703.081 125.185L379.275 686.032L375.372 683.778L699.177 122.931Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M706.985 127.439L710.888 129.692L387.083 690.54L383.179 688.286L706.985 127.439Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M714.792 131.946L718.696 134.2L394.891 695.047L390.987 692.793L714.792 131.946Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M722.6 136.454L726.504 138.708L402.698 699.555L398.795 697.301L722.6 136.454Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M730.407 140.962L734.311 143.216L410.506 704.063L406.602 701.809L730.407 140.962Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M738.215 145.47L742.119 147.723L418.314 708.571L414.41 706.317L738.215 145.47Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M746.023 149.977L749.926 152.231L426.121 713.078L422.217 710.824L746.023 149.977Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M753.83 154.485L757.734 156.739L433.929 717.586L430.025 715.332L753.83 154.485Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M761.638 158.993L765.542 161.247L441.736 722.094L437.833 719.84L761.638 158.993Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M769.445 163.5L773.349 165.754L449.544 726.601L445.64 724.348L769.445 163.5Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M777.253 168.008L781.157 170.262L457.352 731.109L453.448 728.855L777.253 168.008Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M785.061 172.516L788.964 174.77L465.159 735.617L461.255 733.363L785.061 172.516Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M792.868 177.024L796.772 179.278L472.967 740.125L469.063 737.871L792.868 177.024Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M800.676 181.531L804.58 183.785L480.774 744.632L476.871 742.378L800.676 181.531Z"
                    fill="#C7DFF5"
                />
                <path
                    d="M808.484 186.039L812.387 188.293L488.582 749.14L484.678 746.886L808.484 186.039Z"
                    fill="#C7DFF5"
                />
            </g>
        </g>
        <defs>
            <radialGradient
                id="paint0_radial_273_779"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(114) rotate(90) scale(256.4 238.76)"
            >
                <stop stop-color="#447EB4" />
                <stop offset="1" stop-color="#293248" />
            </radialGradient>
        </defs>
    </svg>
</template>
